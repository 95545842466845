var Drawer = {

  init: function(){
    this.tag    = $('.wildbit-tag');
    this.drawer = $('.wildbit-drawer');
    this.dimmer = $('.wildbit-drawer-dimmer');
    this.x      = $('.wildbit-drawer-close');
    
    this.tag.on('click', this.open);
    this.x.add(this.dimmer).on('click', this.close);

  },
  
  open: function() {
    var self = Drawer;
    
    self.tag.addClass('is-active');
    
    setTimeout(function(){
      self.drawer.addClass('animate-in');
      self.dimmer.addClass('animate-in');

      setTimeout(function(){
        self.drawer.addClass('is-visible').removeClass('animate-in');
        self.dimmer.addClass('is-visible').removeClass('animate-in');
      }, 400);

    }, 100);
    
  },
  
  close: function(e) {
    e.preventDefault();
    
    var self = Drawer;
    self.drawer.removeClass('is-visible').addClass('animate-out');
    self.dimmer.removeClass('is-visible').addClass('animate-out');

    setTimeout(function(){
      self.tag.removeClass('is-active');
      self.drawer.removeClass('animate-out');
      self.dimmer.removeClass('animate-out');
    }, 300);
  }
  
}

$(function(){
  Drawer.init();
});
