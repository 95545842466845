var	Landing = {
	init: function () {
		Landing.slides();
	},

	slides: function () {

		var container = $('.js-slides'),
			pointers  = container.find('.feature_pointer'),
			images    = container.find('.feature_slides_wrap'),
			height    = images.height() / pointers.length,
			timeout   = 5000,
			current   = 0,
			tick;

		function hidePointer (i, callback) {
			$(pointers[i]).animate({'margin-left': '250px'}, 300, callback);
		}

		function showPointer (i) {
			$(pointers[i]).animate({'margin-left': '0'}, 300);
		}

		function showImage (i) {
			images.animate({'top': -(height * i) + 'px'}, 300, function () {
				showPointer(i);
			});
		}

		function switchImage () {
			hidePointer(current, function () {
				// Switch to next
				if (current === (pointers.length - 1)) {
					current = 0;
				} else {
					current += 1;
				}

				// Show next
				showImage(current);
			});
		}

		$('.js-switch-slide').on('click.toggleSlide', function (event) {
			event.preventDefault();

			// Stop current animation
			window.clearInterval(tick);
			hidePointer(current);

			// Start a new one
			current = $('.js-switch-slide').index($(this));
			showImage(current);
			tick = window.setInterval(switchImage, timeout);
		});

		tick = window.setInterval(switchImage, timeout);
	}
};

$(document).ready(Landing.init);
